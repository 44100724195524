<template>
	<div class="padding-top-10 contain_member">
		<top :bgWhite='true'></top>
		<div class="content_member bg-white ">
			<div class=" padding-lr-30 padding-top-20 fs24 solid-bottom padding-bottom-15">
				会员周期
			</div>
			<div class="flex align-center flex-direction justify-center padding-tb-30">
				<div class="">
					<div class="flex align-center">
						<span class="fs16 widtht135 text-right">会员价格：</span>
						<span class="text-e54f">￥{{money}}</span>
					</div>
					<div class=" padding-tb-30">
						<div class="flex align-center">
							<p class="fs16 widtht135 text-right">会员周期选择：</p>
							<div class="" v-for="(item,index) in navList" :key='index'>
								<span class="margin-right-15 point radius-100 padding-lr-30 padding-tb-5 "
									@click="tabOne(item,index)"
									:class="currentIndex==index?'line-e54f text-e54f':'bg-f6f7 text-333'">{{item.name}}</span>
							</div>
						</div>
						<div class="fs14 text-theme padding-top-20 padding-left-135">
							*认证时需要缴纳会员费用，如审核失败费用将退回
						</div>					
					</div>
					<div class="flex align-center">
						<span class="fs16 widtht135 text-right">会员权益：</span>
						<span class="text-666">{{body}}会员权益</span>
					</div>
					<div class="agree-box text-999 text-center padding-top-50">
						<input type="checkbox" @click="checkAgree"
							:class="['checkbox', isAgree ? 'checked' : 'default']" class="checkbox default radius-100" />
						<span class="text">
							阅读并同意
							<span class="text-theme point" @click="toTermsPage()">《作家认证协议》</span>
						</span>
					</div>
					<div class=" margin-top-100 margin-bottom-40 flex align-center justify-center">
						<span class="bg-e54f no-border radius-100 padding-lr-60  padding-tb-10 point text-white"
							@click="submitForm()">成为作家</span>
					</div>
				</div>
			</div>
		</div>		
		<bottom :bgWhite='true'></bottom>
		<div class="mask" @click="dialogTableVisible=false" v-if="dialogTableVisible"></div>
		<!-- 协议弹框 -->
		<div class="box-content bg-white radius-3 padding-20" v-if="dialogTableVisible">
			<div class="flex align-center justify-between" style="position: relative;">
				<span class="bold fs20  text-con">作家认证协议</span>
				<img src="../../assets/image/bg_con.png" class="bg-con">
				<i class="el-icon-close" @click="dialogTableVisible=false"></i>
			</div>
			<div class="content margin-top-20 fs14" v-html="content" v-loading="loading"></div>
		</div>
	</div>
</template>

<script>
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		name: 'authen',
		components: {
			bottom,
			top
		},
		data() {
			return {
				loading:true,
				isAgree:false,
				currentIndex: 0, //选择的索引
				dialogTableVisible:false,//是否展示协议
				money:'',//价格
				body:'',//会员权益
				period_id:'', //会员id
				navList: [],
				authenParams:{} ,//认证信息
				content:'' //协议内容
			}
		},
		mounted() {
			this.authenParams = this.$route.params
			this.getMemberperiod()
		},
		methods: {
			/**
			 * 同意条款
			 */
			checkAgree() {
				this.isAgree = !this.isAgree
			},
			// 切换周期
			tabOne(item, index) {
				this.currentIndex = index
				this.money=item.money;
				this.period_id = item.aid;
			},
			// 协议弹框
			toTermsPage(){
				this.getArticle()
				this.dialogTableVisible=true
			},
			// 获取会员周期
			getMemberperiod(){
			  this.ajax('post', '/v1/613b25fc23885', {}, res => {
			  	if (res.code == 1) {
			  		this.navList = res.data;
					this.money = res.data[0].money;
					this.period_id = res.data[0].aid
			  	} else {
			  		this.$message.error(res.msg);
			  	}
			  
			  }, err => {
			  	console.log(err);
			  	this.hadGotCode = false
			  })
			},
			// 获取作家认证协议
			getArticle(){
			  this.ajax('post', '/v1/5d63befcb25d9', {
				  category_id:24
			  }, res => {
				  this.loading  = false;
			  	if (res.code == 1) {
			  		this.content = res.data.content;
			  	} else {
			  		this.$message.error(res.msg);
			  	}
			  
			  }, err => {
			  	console.log(err);
			  	this.hadGotCode = false
			  })
			},
			submitForm() {
				if(!this.isAgree){
					this.$message.error('请先同意作家认证协议');
					return
				}
				this.authenParams.period_id = this.period_id;
				this.authenParams.auth_type = 1;
				console.log(this.authenParams);
				this.ajax('post', '/v1/userAddons/certification', this.authenParams, res => {
					if (res.code == 1) {
						this.addOrder(res.data.id)
					} else {
						this.$message.error(res.msg);
					}
				}, err => {
					console.log(err);
				})
			},
			// 统一下单接口
			addOrder(certified_id){
				this.ajax('post', '/v1/5d784b976769e', {
					order_type:4, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
					period_id:this.period_id,
					certified_id:certified_id
				}, res => {
					if (res.code == 1) {
						let order_sn = res.data.order_sn;
						this.$router.replace({
							path: '/payMoney',
							query:{
								order_sn:res.data.order_sn,
								order_money:res.data.money,
								return_url:JSON.stringify(window.location.href)
							}
						})
					} else {
						this.$message.error(res.msg);
					}
				})
			}
			
		}
	}
</script>

<style scoped>
	.contain_member {
		width: 100vw;
		height: 100vh;
		background-color: #F8F8F8;
		overflow: auto;
	}

	.content_member {
		width: 80%;
		height: auto;
		margin: 80px auto;
	}
	.box-content {
		position: fixed;
		left: 0;
		right: 0;
		width: 40%;
		top: 50%;
		transform: translateY(-50%);
		height: 400upx;
		z-index: 1510;
		margin: auto;
	}
	.bg-con {
		position: absolute;
		width: 112px;
		height: 10px;
		bottom: 0;
		left: 0;
	}
	.text-con {
		position: relative;
		left: 0;
		z-index: 10;
	}
	.avatar {
		width: 200px;
		height: 126px;
		display: block;
	}

	/deep/.el-input {
		width: 320px;
		height: 36px;
	}
</style>
